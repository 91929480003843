import { SchoolScheduleSearchParams } from './../api/classEnrollmentsApi';
import { buildQueryString } from '@@helpers/qs';
import { WhosHereChildSearchable } from '@@api/admissionsApi';
import { PermissionsEnum } from '@@auth/types/permissionsEnum';
import { toBase64Url } from '@@helpers/base64Helpers';
import { BusMode } from '@@helpers/busHelpers';
import * as Pages from '@@pages';
import {
  faBus,
  faCircleDollarToSlot,
  faClipboardList,
  faDoorOpen,
  faGraduationCap,
  faGrip,
  faHand,
  faPeopleLine,
  faPersonWalkingArrowRight,
  faTabletAlt,
} from '@fortawesome/free-solid-svg-icons';
import { createModalRoute } from './helpers/createModalRoute';
import { LedgerItemParentRoutes } from './types';

/**************************************************************
 * Centralized route definitions
 *
 * All the application routes should be defined here,
 * and then imported for usage in the router, links, menus, etc.
 **************************************************************/

/**
 * Root routes should have no paths prefix,
 * and should load pages from the /pages directory.
 */
export const rootRoutes = {
  home: () => ({
    path: '/',
    component: Pages.HomePage,
    label: 'Log In',
  }),
  forgotPassword: () => ({
    path: '/forgot-password',
    component: Pages.ForgotPasswordPage,
    label: 'Forgot Password',
  }),
  passwordReset: () => ({
    path: `/reset-password`,
    component: Pages.ResetPasswordPage,
    label: 'Reset Password',
  }),
};

/**
 * Admin routes should have paths prefixed with /admin,
 * and should load pages from the /pages/admin directory.
 */
export const adminRoutes = {
  home: () => ({
    path: '/admin',
    component: Pages.admin.HomePage,
    label: 'Home',
  }),

  /**
   * Admin services
   */
  services: {
    samples: () => ({
      path: '/admin/services/sample',
      component: Pages.admin.services.SamplesManagerPage,
      label: 'Sample Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    allergies: () => ({
      path: '/admin/services/allergies/',
      component: Pages.admin.services.AllergyManagerPage,
      label: 'Allergy Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    allergyEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.allergies(),
      path: `/admin/services/allergies/${id}/edit`,
      component: Pages.admin.services.AllergyEditPage,
      label: 'Edit Allergy',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    allergyNew: () => ({
      parent: routes.admin.services.allergies(),
      path: '/admin/services/allergies/new',
      component: Pages.admin.services.AllergyNewPage,
      label: 'New Allergy',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    foodPrograms: () => ({
      path: '/admin/services/food-programs/',
      component: Pages.admin.services.FoodProgramManagerPage,
      label: 'Food Program Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    foodProgramEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.foodPrograms(),
      path: `/admin/services/food-programs/${id}/edit`,
      component: Pages.admin.services.FoodProgramEditPage,
      label: 'Edit Food Program',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    foodProgramNew: () => ({
      parent: routes.admin.services.foodPrograms(),
      path: '/admin/services/food-programs/new',
      component: Pages.admin.services.FoodProgramNewPage,
      label: 'New Food Program',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    regions: () => ({
      path: '/admin/services/regions/',
      component: Pages.admin.services.RegionManagerPage,
      label: 'Region Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    regionEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.regions(),
      path: `/admin/services/regions/${id}/edit`,
      component: Pages.admin.services.RegionEditPage,
      label: 'Edit Region',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    regionNew: () => ({
      parent: routes.admin.services.regions(),
      path: '/admin/services/regions/new',
      component: Pages.admin.services.RegionNewPage,
      label: 'New Region',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    schools: () => ({
      path: '/admin/services/schools/',
      component: Pages.admin.services.SchoolManagerPage,
      label: 'School Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    schoolEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.schools(),
      path: `/admin/services/schools/${id}/edit`,
      component: Pages.admin.services.SchoolEditPage,
      label: 'Edit School',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    schoolNew: () => ({
      parent: routes.admin.services.schools(),
      path: '/admin/services/schools/new',
      component: Pages.admin.services.SchoolNewPage,
      label: 'New School',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    rooms: () => ({
      path: '/admin/services/rooms/',
      component: Pages.admin.services.RoomManagerPage,
      label: 'Room Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    roomEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.rooms(),
      path: `/admin/services/rooms/${id}/edit`,
      component: Pages.admin.services.RoomEditPage,
      label: 'Edit Room',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    roomNew: () => ({
      parent: routes.admin.services.rooms(),
      path: '/admin/services/rooms/new',
      component: Pages.admin.services.RoomNewPage,
      label: 'New Room',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programNames: () => ({
      path: '/admin/services/program-names',
      component: Pages.admin.services.ProgramNameManagerPage,
      label: 'Program Names (Step 2)',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programNameEdit: (id: string | number = ':id') => ({
      parent: routes.admin.services.programNames(),
      path: `/admin/services/program-names/${id}/edit`,
      component: Pages.admin.services.ProgramNameEditPage,
      label: 'Edit Program Name',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programNameNew: () => ({
      parent: routes.admin.services.programNames(),
      path: '/admin/services/program-names/new',
      component: Pages.admin.services.ProgramNameNewPage,
      label: 'New Program Name',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programTypes: () => ({
      path: '/admin/services/program-types',
      component: Pages.admin.services.ProgramTypeManagerPage,
      label: 'Program Types (Step 1)',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programTypeEdit: (id: string | number = ':id') => ({
      parent: routes.admin.services.programTypes(),
      path: `/admin/services/program-types/${id}/edit`,
      component: Pages.admin.services.ProgramTypeEditPage,
      label: 'Edit Program Type',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programTypeNew: () => ({
      parent: routes.admin.services.programTypes(),
      path: '/admin/services/program-types/new',
      component: Pages.admin.services.ProgramTypeNewPage,
      label: 'New Program Type',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programs: () => ({
      path: '/admin/services/programs/',
      component: Pages.admin.services.ProgramManagerPage,
      label: 'Program Setup (Step 4)',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.programs(),
      path: `/admin/services/programs/${id}/edit`,
      component: Pages.admin.services.ProgramEditPage,
      label: 'Edit Program',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programEdit_programGroupNewModal: (id: number | string = ':id') =>
      createModalRoute({
        parent: routes.admin.services.programEdit(id),
        subPath: 'groups/new',
        label: 'New Program Group',
        permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
      }),
    programEdit_programGroupEditModal: (
      id: number | string = ':id',
      programGroupItemId: number | string = ':programGroupItemId'
    ) =>
      createModalRoute({
        parent: routes.admin.services.programEdit(id),
        subPath: `/groups/${programGroupItemId}/edit`,
        label: 'Edit Program Group',
        permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
      }),
    programEdit_programGroupLinkModal: (
      id: number | string = ':id',
      programGroupItemId: number | string = ':programGroupItemId'
    ) =>
      createModalRoute({
        parent: routes.admin.services.programEdit(id),
        subPath: `/groups/${programGroupItemId}/link-new`,
        label: 'Add Linked Program Group',
        permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
      }),
    programNew: () => ({
      parent: routes.admin.services.programs(),
      path: '/admin/services/programs/new',
      component: Pages.admin.services.ProgramNewPage,
      label: 'New Program',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programDiscounts: () => ({
      path: '/admin/services/program-discounts/',
      component: Pages.admin.services.ProgramDiscountManagerPage,
      label: 'Program Discounts (Step 3)',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programDiscountEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.programDiscounts(),
      path: `/admin/services/program-discounts/${id}/edit`,
      component: Pages.admin.services.ProgramDiscountEditPage,
      label: 'Edit Program Discount',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programDiscountNew: () => ({
      parent: routes.admin.services.programDiscounts(),
      path: '/admin/services/program-discounts/new',
      component: Pages.admin.services.ProgramDiscountNewPage,
      label: 'New Program Discount',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programRegistrations: (
      programId: number | string = ':programId',
      programGroupItemId: number | string = ':programGroupItemId'
    ) => ({
      parent: routes.admin.services.programEdit(programId),
      path: `/admin/services/programs/${programId}/registrations/${programGroupItemId}`,
      component: Pages.admin.services.ProgramRegistrationManagerPage,
      label: 'Program Registrations',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    programRegistrations_editModal: (
      programId: number | string = ':programId',
      programGroupItemId: number | string = ':programGroupItemId',
      familyId: number | string = ':familyId',
      programEnrollmentId: string = ':programEnrollmentId'
    ) =>
      createModalRoute({
        parent: routes.admin.services.programRegistrations(
          programId,
          programGroupItemId
        ),
        subPath: `/${familyId}/${programEnrollmentId}/edit`,
        label: 'Edit Program Registration',
        permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
      }),
    publicPrivateSchools: () => ({
      path: '/admin/services/public-private-schools',
      component: Pages.admin.services.PublicPrivateSchoolManagerPage,
      label: 'Public/Private School Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    publicPrivateSchoolEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.publicPrivateSchools(),
      path: `/admin/services/public-private-schools/${id}/edit`,
      component: Pages.admin.services.PublicPrivateSchoolEditPage,
      label: 'Edit Public/Private School',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    publicPrivateSchoolNew: () => ({
      parent: routes.admin.services.publicPrivateSchools(),
      path: '/admin/services/public-private-schools/new',
      component: Pages.admin.services.PublicPrivateSchoolNewPage,
      label: 'New Public/Private School',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    ledgerItemCategories: () => ({
      path: '/admin/services/ledger-item-categories/',
      component: Pages.admin.services.LedgerItemCategoryManagerPage,
      label: 'Ledger Item Category Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    ledgerItemCategoryEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.ledgerItemCategories(),
      path: `/admin/services/ledger-item-categories/${id}/edit`,
      component: Pages.admin.services.LedgerItemCategoryEditPage,
      label: 'Edit Ledger Item Category',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    ledgerItemCategoryNew: () => ({
      parent: routes.admin.services.ledgerItemCategories(),
      path: '/admin/services/ledger-item-categories/new',
      component: Pages.admin.services.LedgerItemCategoryNewPage,
      label: 'New Ledger Item Category',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    fineFeeCoupons: () => ({
      path: '/admin/services/establishing-fees/',
      component: Pages.admin.services.FineFeeCouponManagerPage,
      label: 'Establishing Fee Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    fineFeeCouponEdit: (
      fineFeeCouponId: number | string = ':fineFeeCouponId'
    ) => ({
      parent: routes.admin.services.fineFeeCoupons(),
      path: `/admin/services/establishing-fees/${fineFeeCouponId}`,
      component: Pages.admin.services.FineFeeCouponEditPage,
      label: 'Edit Establishing Fee',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    fineFeeCouponEdit_schoolEditModal: (
      fineFeeCouponId: number | string = ':fineFeeCouponId',
      fineFeeCouponSchoolId: number | string = ':fineFeeCouponSchoolId'
    ) =>
      createModalRoute({
        parent: routes.admin.services.fineFeeCouponEdit(fineFeeCouponId),
        subPath: `/schools/${fineFeeCouponSchoolId}/edit`,
        label: 'Edit Establishing Fee For School',
        permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
      }),
    fineFeeCouponNew: () => ({
      parent: routes.admin.services.fineFeeCoupons(),
      path: '/admin/services/establishing-fees/new',
      component: Pages.admin.services.FineFeeCouponNewPage,
      label: 'New Establishing Fee',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    assigningFees: () => ({
      path: '/admin/services/assigning-fees',
      component: Pages.admin.services.AssigningFeesPage,
      label: 'Assigning Fees to Classes & Programs',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    accountStatuses: () => ({
      path: '/admin/services/account-statuses/',
      component: Pages.admin.services.AccountStatusManagerPage,
      label: 'Account Status Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    accountStatusEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.accountStatuses(),
      path: `/admin/services/account-statuses/${id}/edit`,
      component: Pages.admin.services.AccountStatusEditPage,
      label: 'Edit Account Status',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    accountStatusNew: () => ({
      parent: routes.admin.services.accountStatuses(),
      path: '/admin/services/account-statuses/new',
      component: Pages.admin.services.AccountStatusNewPage,
      label: 'New Account Status',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    //
    authorizeKeys: () => ({
      path: '/admin/services/authorize-keys/',
      component: Pages.admin.services.AuthorizeKeyManagerPage,
      label: 'Authorize.Net Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    authorizeKeyEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.authorizeKeys(),
      path: `/admin/services/authorize-keys/${id}/edit`,
      component: Pages.admin.services.AuthorizeKeyEditPage,
      label: 'Edit Authorize.Net Key',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    authorizeKeyNew: () => ({
      parent: routes.admin.services.authorizeKeys(),
      path: '/admin/services/authorize-keys/new',
      component: Pages.admin.services.AuthorizeKeyNewPage,
      label: 'New Authorize.Net Key',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    //
    parentChecklists: () => ({
      path: '/admin/services/parent-checklists/',
      component: Pages.admin.services.ParentChecklistManagerPage,
      label: 'Parent Checklist Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    parentChecklistEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.parentChecklists(),
      path: `/admin/services/parent-checklists/${id}/edit`,
      component: Pages.admin.services.ParentChecklistEditPage,
      label: 'Edit Parent Checklist',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    parentChecklistNew: () => ({
      parent: routes.admin.services.parentChecklists(),
      path: '/admin/services/parent-checklists/new',
      component: Pages.admin.services.ParentChecklistNewPage,
      label: 'New Parent Checklist',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    medicalDocumentationTypes: () => ({
      path: '/admin/services/medical-documentation-types/',
      component: Pages.admin.services.MedicalDocumentationTypeManagerPage,
      label: 'Enrollment Documentation Type Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    medicalDocumentationTypeEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.medicalDocumentationTypes(),
      path: `/admin/services/medical-documentation-types/${id}/edit`,
      component: Pages.admin.services.MedicalDocumentationTypeEditPage,
      label: 'Edit Enrollment Documentation Type',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    medicalDocumentationTypeNew: () => ({
      parent: routes.admin.services.medicalDocumentationTypes(),
      path: '/admin/services/medical-documentation-types/new',
      component: Pages.admin.services.MedicalDocumentationTypeNewPage,
      label: 'New Enrollment Documentation Type',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    medicalDocumentations: () => ({
      path: '/admin/services/medical-documentations/',
      component: Pages.admin.services.MedicalDocumentationManagerPage,
      label: 'Enrollment Documentation Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    medicalDocumentationEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.medicalDocumentations(),
      path: `/admin/services/medical-documentations/${id}/edit`,
      component: Pages.admin.services.MedicalDocumentationEditPage,
      label: 'Edit Enrollment Documentation',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    medicalDocumentationNew: () => ({
      parent: routes.admin.services.medicalDocumentations(),
      path: '/admin/services/medical-documentations/new',
      component: Pages.admin.services.MedicalDocumentationNewPage,
      label: 'New Enrollment Documentation',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    waivers: () => ({
      path: '/admin/services/waivers',
      component: Pages.admin.services.WaiverManagerPage,
      label: 'Waiver Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    waiverEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.waivers(),
      path: `/admin/services/waivers/${id}/edit`,
      component: Pages.admin.services.WaiverEditPage,
      label: 'Edit Waiver',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    transitionLogs: () => ({
      path: '/admin/services/transition-logs',
      component: Pages.admin.services.TransitionLogManagerPage,
      label: 'Transition Log Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    transitionLogNew: () => ({
      parent: routes.admin.services.transitionLogs(),
      path: '/admin/services/transition-logs/new',
      component: Pages.admin.services.TransitionLogNewPage,
      label: 'New Transition Log',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    transitionLogEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.transitionLogs(),
      path: `/admin/services/transition-logs/${id}/edit`,
      component: Pages.admin.services.TransitionLogEditPage,
      label: 'Edit Transition Log',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    teacherCountLogs: () => ({
      path: '/admin/services/teacher-count-logs',
      component: Pages.admin.services.TeacherCountLogManagerPage,
      label: 'Teacher Count Log Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    teacherCountLogNew: () => ({
      parent: routes.admin.services.teacherCountLogs(),
      path: '/admin/services/teacher-count-logs/new',
      component: Pages.admin.services.TeacherCountLogNewPage,
      label: 'New Teacher Count Log',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    teacherCountLogEdit: (id: number | string = ':id') => ({
      parent: routes.admin.services.teacherCountLogs(),
      path: `/admin/services/teacher-count-logs/${id}/edit`,
      component: Pages.admin.services.TeacherCountLogEditPage,
      label: 'Edit Teacher Count Log',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    schoolImporter: () => ({
      path: '/admin/school-importer',
      component: Pages.admin.services.SchoolImporterPage,
      label: 'School Importer',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    schoolImporter_detailsModal: () =>
      createModalRoute({
        parent: routes.admin.services.schoolImporter(),
        subPath: `/admin/school-importer/details`,
        label: 'School Importer Details',
        permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
      }),
  },

  /**
   * Staff portal
   */

  staffPortal: {
    familyOverview: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/overview`,
      component: Pages.staff.FamilyOverviewPage,
      label: 'Family Overview',
      permission: PermissionsEnum.FAMILY_CAN_VIEW,
    }),
    familyCaregiverManager: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      path: `/admin/staff-portal/families/${familyId}/caregivers`,
      component: Pages.staff.FamilyCaregiverManagerPage,
      label: 'Family Caregivers',
      permission: PermissionsEnum.FAMILY_CAREGIVER_CAN_VIEW_DETAILS,
    }),
    familyCaregiverNew: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyCaregiverManager(familyId),
      path: `/admin/staff-portal/families/${familyId}/caregivers/new`,
      component: Pages.staff.FamilyCaregiverNewPage,
      label: 'New Caregiver',
      permission: PermissionsEnum.FAMILY_CAN_ADD_NEW,
    }),
    familyCaregiverEdit: (
      familyId: string = ':familyId',
      caregiverId: string = ':caregiverId'
    ) => ({
      parent: adminRoutes.staffPortal.familyCaregiverManager(familyId),
      path: `/admin/staff-portal/families/${familyId}/caregivers/${caregiverId}/edit`,
      component: Pages.staff.FamilyCaregiverEditPage,
      label: 'Edit Caregiver',
      permission: PermissionsEnum.FAMILY_CAREGIVER_CAN_VIEW_DETAILS,
    }),
    familyCaregiverHistory: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyCaregiverManager(familyId),
      path: `/admin/staff-portal/families/${familyId}/caregivers/history`,
      component: Pages.staff.FamilyCaregiverHistoryPage,
      label: 'Family Caregiver History',
      permission: PermissionsEnum.FAMILY_CAREGIVER_CAN_VIEW_HISTORY,
    }),
    familyCaregiverHistory_detailsModal: (
      familyId: string = ':familyId',
      _seq: string | number = ':_seq'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.familyCaregiverHistory(familyId),
        subPath: `/${_seq}/details`,
        label: 'Family Caregiver History',
        permission: PermissionsEnum.FAMILY_CAREGIVER_CAN_VIEW_HISTORY,
      }),
    familyChildManager: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      path: `/admin/staff-portal/families/${familyId}/children`,
      component: Pages.staff.FamilyChildManagerPage,
      label: 'Children',
      permission: PermissionsEnum.FAMILY_CHILD_CAN_VIEW_DETAILS,
    }),
    familyEdit: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      path: `/admin/staff-portal/families/${familyId}/edit`,
      component: Pages.staff.FamilyEditPage,
      label: 'Family Info & Options',
      permission: PermissionsEnum.FAMILY_CAN_VIEW_INFO,
    }),
    familyNew: () => ({
      path: '/admin/staff-portal/families/new',
      component: Pages.staff.FamilyNewPage,
      label: 'New Family',
      permission: PermissionsEnum.FAMILY_CAN_ADD_NEW,
      icon: faPeopleLine,
    }),
    familyEditAutomatedBillingType: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyEdit(familyId),
      path: `/admin/staff-portal/families/${familyId}/billing-schedule/edit`,
      component: Pages.staff.FamilyEditAutomatedBillingType,
      label: 'Change Automated Billing for Family',
      permission: PermissionsEnum.FAMILY_CAN_EDIT_INFO,
    }),
    familyEditAutomatedBillingTypeCompleted: (
      familyId: string = ':familyId'
    ) => ({
      parent: adminRoutes.staffPortal.familyEdit(familyId),
      path: `/admin/staff-portal/families/${familyId}/billing-schedule/completed`,
      component: Pages.staff.FamilyEditAutomatedBillingTypeCompleted,
      label: 'Automated Billing Changed',
      permission: PermissionsEnum.FAMILY_CAN_EDIT_INFO,
    }),
    familyNotes: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/notes`,
      component: Pages.staff.FamilyNotesPage,
      label: 'Family Notes',
      permission: PermissionsEnum.FAMILY_NOTES_CAN_ACCESS_AND_MODIFY,
    }),
    familyNotes_detailsModal: (
      familyId: string = ':familyId',
      familyNoteId: string = ':familyNoteId'
    ) =>
      createModalRoute({
        parent: adminRoutes.staffPortal.familyNotes(familyId),
        subPath: `/${familyNoteId}/details`,
        label: 'Family Note',
        permission: PermissionsEnum.FAMILY_NOTES_CAN_ACCESS_AND_MODIFY,
      }),
    familySignedDocuments: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      path: `/admin/staff-portal/families/${familyId}/signed-documents`,
      component: Pages.staff.FamilySignedDocumentsPage,
      label: 'Signed Documents',
      permission: PermissionsEnum.FAMILY_SIGNED_DOCUMENTS_CAN_ACCESS,
    }),
    childEdit: (
      familyId: string = ':familyId',
      childId: string = ':childId'
    ) => ({
      parent: adminRoutes.staffPortal.familyChildManager(familyId),
      path: `/admin/staff-portal/families/${familyId}/children/${childId}/edit`,
      component: Pages.staff.ChildEditPage,
      label: 'Edit Child',
      permission: PermissionsEnum.FAMILY_CHILD_CAN_VIEW_DETAILS,
    }),
    childNew: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyChildManager(familyId),
      path: `/admin/staff-portal/families/${familyId}/children/new`,
      component: Pages.staff.ChildNewPage,
      label: 'New Child',
      permission: PermissionsEnum.FAMILY_CAN_ADD_NEW,
    }),
    classEnrollmentNew: (
      familyId: string = ':familyId',
      childId?: string,
      params?: SchoolScheduleSearchParams
    ) => {
      /* Building the qs */
      const { subjectId, schoolId, school, startTimeOnly, seasonTypeId, days } =
        params || {};

      const qs = buildQueryString([
        { childId },
        { school },
        { schoolId },
        { subjectId },
        { seasonTypeId },
        { startTimeOnly },
        { days },
      ]);
      return {
        path: `/admin/staff-portal/families/${familyId}/class-enrollments/new${qs}`,
        component: Pages.staff.ClassEnrollmentNewPage,
        label: 'Enroll in a Class',
        permission: PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_ACCESS,
      };
    },
    classDetailEnrollment: (
      familyId: string = ':familyId',
      childId: string = ':childId',
      classInfoId: string = ':classInfoId',
      params?: SchoolScheduleSearchParams
    ) => {
      /* Building the qs */
      const { subjectId, schoolId, school, startTimeOnly, seasonTypeId, days } =
        params || {};

      const qs = buildQueryString([
        { schoolId },
        { school },
        { subjectId },
        { startTimeOnly },
        { seasonTypeId },
        { days },
      ]);
      return {
        parent: routes.admin.staffPortal.classEnrollmentNew(
          familyId,
          childId,
          params
        ),
        component: Pages.staff.ClassInfoPage,
        path: `/admin/staff-portal/families/${familyId}/child/${childId}/class/${classInfoId}/${qs}`,
        label: 'Class Details',
        permission: PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_ACCESS,
      };
    },
    classEnrollmentComplete: (
      familyId: string = ':familyId',
      childId: string = ':childId',
      classInfoId: string = ':classInfoId',
      seasonId: string | number = ':seasonId',
      params?: SchoolScheduleSearchParams
    ) => {
      /* Building the qs */
      const { subjectId, schoolId, school, startTimeOnly, seasonTypeId, days } =
        params || {};

      const qs = buildQueryString([
        { schoolId },
        { school },
        { subjectId },
        { startTimeOnly },
        { seasonTypeId },
        { days },
      ]);
      return {
        parent: routes.admin.staffPortal.classEnrollmentNew(
          familyId,
          childId,
          params
        ),
        path: `/admin/staff-portal/families/${familyId}/children/${childId}/classes-enrollment/${classInfoId}/season/${seasonId}/complete/${qs}`,
        component: Pages.staff.ClassEnrollmentCompletePage,
        label: 'Complete Enrollment',
        permission: PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_ACCESS,
      };
    },
    classEnrollmentCompleted: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.classEnrollmentNew(familyId), // TODO: update with listing route
      path: `/admin/staff-portal/families/${familyId}/classes-enrollment/completed`,
      component: Pages.staff.ClassEnrollmentCompletedPage,
      label: 'Class Enrollment Complete',
      permission: PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_ACCESS,
    }),
    classSchedule: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      path: `/admin/staff-portal/families/${familyId}/class-schedule`,
      component: Pages.staff.ClassSchedulePage,
      label: 'Current Classes',
      permission: PermissionsEnum.FAMILY_CURRENT_CLASSES_CAN_ACCESS,
    }),
    classDetailSchedule: (
      familyId: string = ':familyId',
      childId: string = ':childId',
      classInfoId: string = ':classInfoId'
    ) => ({
      parent: routes.admin.staffPortal.classSchedule(familyId),
      component: Pages.staff.ClassInfoPage,
      path: `/admin/staff-portal/families/${familyId}/class-schedule/child/${childId}/class/${classInfoId}`,
      label: 'Class Details',
      permission: PermissionsEnum.FAMILY_CURRENT_CLASSES_CAN_ACCESS,
    }),
    upcomingEvents: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/events`,
      component: Pages.staff.UpcomingEventsPage,
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      label: 'Upcoming Events',
      permission: PermissionsEnum.FAMILY_UPCOMING_EVENTS_CAN_ACCESS,
    }),
    classSchedule_previousClassesModal: (
      familyId: string = ':familyId',
      childId: string = ':childId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.classSchedule(familyId),
        subPath: `/children/${childId}/previous-enrollments`,
        label: 'Previous Classes',
        permission: PermissionsEnum.FAMILY_PREVIOUS_CLASSES_CAN_ACCESS,
      }),
    classSchedule_classDetailsModal: (
      familyId: string = ':familyId',
      classId: string = ':classId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.classSchedule(familyId),
        subPath: `/class/${classId}/details`,
        label: 'Class Details',
        permission: PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_ACCESS,
      }),
    programEnrollments: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      path: `/admin/staff-portal/families/${familyId}/program-enrollments`,
      component: Pages.staff.ProgramEnrollmentManagerPage,
      label: 'Program Enrollments in Progress',
      permission: PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_ACCESS,
    }),
    programEnrollmentNew: (
      familyId: string = ':familyId',
      childId?: string
    ) => {
      const qs = childId ? `?childId=${childId}` : '';
      return {
        parent: adminRoutes.staffPortal.programEnrollments(familyId),
        path: `/admin/staff-portal/families/${familyId}/program-enrollments/new${qs}`,
        component: Pages.staff.ProgramEnrollmentNewPage,
        label: 'Enroll in a Program',
        permission: PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_ACCESS,
      };
    },
    programEnrollmentFormReport: (familyId: string = ':familyId') => {
      return {
        parent: adminRoutes.staffPortal.programSchedule(familyId),
        path: `/admin/staff-portal/families/${familyId}/program-enrollments/form-report`,
        component: Pages.staff.ProgramEnrollmentFormReportPage,
        label: 'Enrollment Form Report',
        permission: PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_ACCESS, // TODO: Check this
      };
    },
    programEnrollmentEdit: (
      familyId: string = ':familyId',
      programEnrollmentId: string = ':programEnrollmentId'
    ) => ({
      parent: adminRoutes.staffPortal.programEnrollments(familyId),
      path: `/admin/staff-portal/families/${familyId}/program-enrollments/${programEnrollmentId}/edit`,
      component: Pages.staff.ProgramEnrollmentEditPage,
      label: 'Edit Program Enrollment',
    }),
    programEnrollmentComplete: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.programEnrollments(familyId),
      path: `/admin/staff-portal/families/${familyId}/program-enrollments/complete`,
      component: Pages.staff.ProgramEnrollmentCompletePage,
      label: 'Complete Program Enrollment',
      permission: PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_ACCESS,
    }),
    programEnrollmentCompleted: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.programEnrollments(familyId),
      path: `/admin/staff-portal/families/${familyId}/program-enrollments/completed`,
      component: Pages.staff.ProgramEnrollmentCompletedPage,
      label: 'Program Enrollment Complete',
      permission: PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_ACCESS,
    }),
    programSchedule: (familyId: string = ':familyId') => ({
      parent: adminRoutes.staffPortal.familyOverview(familyId),
      path: `/admin/staff-portal/families/${familyId}/program-schedule`,
      component: Pages.staff.ProgramSchedulePage,
      label: 'Current Programs',
      permission: PermissionsEnum.FAMILY_CURRENT_PROGRAMS_CAN_ACCESS,
    }),
    programSchedule_editModal: (
      familyId: string = ':familyId',
      programEnrollmentId: string = ':programEnrollmentId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.programSchedule(familyId),
        subPath: `/enrollments/${programEnrollmentId}/edit`,
        label: 'Edit Program Enrollment',
        permission: PermissionsEnum.FAMILY_CURRENT_PROGRAMS_CAN_ACCESS,
      }),
    programSchedule_historyModal: (
      familyId: string = ':familyId',
      programEnrollmentId: string = ':programEnrollmentId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.programSchedule(familyId),
        subPath: `/enrollments/${programEnrollmentId}/history`,
        label: 'Program Enrollment History',
        permission: PermissionsEnum.FAMILY_PROGRAM_HISTORY_CAN_ACCESS,
      }),
    programSchedule_historyDetailsModal: (
      familyId: string = ':familyId',
      programEnrollmentId: string = ':programEnrollmentId',
      _seq: string | number = ':_seq'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.programSchedule_historyModal(
          familyId,
          programEnrollmentId
        ),
        subPath: `/enrollments/${programEnrollmentId}/history/${_seq}/details`,
        label: 'Program Enrollment History Detail',
        permission: PermissionsEnum.FAMILY_PROGRAM_HISTORY_CAN_ACCESS,
      }),
    programSchedule_previousProgramsModal: (
      familyId: string = ':familyId',
      childId: string = ':childId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.programSchedule(familyId),
        subPath: `/children/${childId}/previous-enrollments`,
        label: 'Previous Programs',
        permission: PermissionsEnum.FAMILY_PREVIOUS_PROGRAMS_CAN_ACCESS,
      }),
    familyLedgerOverview: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/ledger-overview`,
      component: Pages.staff.FamilyLedgerOverviewPage,
      label: 'Family Ledger Overview',
      permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
    }),
    familyLedgerOverview_addLedgerItemModal: (familyId: string = ':familyId') =>
      createModalRoute({
        parent: routes.admin.staffPortal.familyLedgerOverview(familyId),
        subPath: `/ledger-items/new`,
        label: 'Add Family Ledger Item',
        permission: PermissionsEnum.LEDGER_CAN_ADD,
      }),
    familyLedgerOverview_editLedgerItemModal: (
      familyId: string = ':familyId',
      ledgerItemId: string = ':ledgerItemId',
      fromParent?: LedgerItemParentRoutes
    ) =>
      createModalRoute({
        parent:
          fromParent && fromParent === LedgerItemParentRoutes.DETAILS
            ? routes.admin.staffPortal.familyLedger(familyId)
            : routes.admin.staffPortal.familyLedgerOverview(familyId),
        subPath: `/ledger-items/${ledgerItemId}/edit`,
        label: 'Edit Family Ledger Item',
        permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
      }),
    familyLedgerOverview_estimatedPaymentsModal: (
      familyId: string = ':familyId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.familyLedgerOverview(familyId),
        subPath: `/estimated-payments`,
        label: 'Estimated Billing',
        permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
      }),
    familyLedger: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/ledger`,
      component: Pages.staff.FamilyLedgerPage,
      label: 'Family Ledger Items',
      permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
    }),
    familyLedger_addLedgerItemModal: (familyId: string = ':familyId') =>
      createModalRoute({
        parent: routes.admin.staffPortal.familyLedger(familyId),
        subPath: `/ledger-items/new`,
        label: 'Add Family Ledger Item',
        permission: PermissionsEnum.LEDGER_CAN_ADD,
      }),
    familyLedger_editLedgerItemModal: (
      familyId: string = ':familyId',
      ledgerItemId: string = ':ledgerItemId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.familyLedger(familyId),
        subPath: `/ledger-items/${ledgerItemId}/edit`,
        label: 'Edit Family Ledger Item',
        permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
      }),
    familyLedger_ledgerItemHistoryModal: (
      familyId: string = ':familyId',
      ledgerItemId: string = ':ledgerItemId'
    ) =>
      createModalRoute({
        parent: routes.admin.staffPortal.familyLedger(familyId),
        subPath: `/ledger-item/${ledgerItemId}/history/search`,
        label: 'Family Ledger Item History',
        permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
      }),
    familyLedger_estimatedPaymentsModal: (familyId: string = ':familyId') =>
      createModalRoute({
        parent: routes.admin.staffPortal.familyLedger(familyId),
        subPath: `/estimated-payments`,
        label: 'Estimated Billing',
        permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
      }),
    familyLedgerReport: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/ledger-report`,
      component: Pages.staff.FamilyLedgerReportPage,
      label: 'Family Ledger Report',
      permission: PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
    }),
    familyMakePayment: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/make-payment`,
      component: Pages.staff.FamilyMakePaymentPage,
      label: 'Make Payment for Family',
      permission: PermissionsEnum.FAMILY_MAKE_PAYMENT_CAN_ACCESS,
    }),
    familyMakePaymentCompleted: (familyId: string = ':familyId') => ({
      path: `/admin/staff-portal/families/${familyId}/make-payment/completed`,
      component: Pages.staff.FamilyMakePaymentCompletedPage,
      label: 'Payment Complete',
    }),
    calendar: () => ({
      path: '/admin/staff-portal/calendar',
      component: Pages.staff.CalendarPage,
      label: 'Calendar',
      permission: PermissionsEnum.GLOBAL_CALENDAR_CAN_ACCESS,
    }),
    classDetail: (classInfoId: string = ':classInfoId') => ({
      // parent: calendar for now - I don't think this is accessed anywhere else at the moment
      parent: routes.admin.staffPortal.calendar(),
      component: Pages.staff.ClassInfoPage,
      path: `/admin/staff-portal/class/${classInfoId}`,
      label: 'Class Details',
      permission: PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_ACCESS,
    }),
    ledger: () => ({
      path: '/admin/staff-portal/ledger',
      component: Pages.staff.LedgerForOrgPage,
      label: 'Ledger',
      permission: PermissionsEnum.GLOBAL_LEDGER_CAN_ACCESS,
    }),
    ledger_addLedgerItemModal: () =>
      createModalRoute({
        parent: routes.admin.staffPortal.ledger(),
        subPath: `/ledger-items/new`,
        label: 'Add Ledger Item',
        permission: PermissionsEnum.LEDGER_CAN_ADD,
      }),
    ledger_editLedgerItemModal: (ledgerItemId: string = ':ledgerItemId') =>
      createModalRoute({
        parent: routes.admin.staffPortal.ledger(),
        subPath: `/ledger-items/${ledgerItemId}/edit`,
        label: 'Edit Ledger Item',
        permission: PermissionsEnum.GLOBAL_LEDGER_CAN_ACCESS,
      }),
    ledger_ledgerItemHistoryModal: (ledgerItemId: string = ':ledgerItemId') =>
      createModalRoute({
        parent: routes.admin.staffPortal.ledger(),
        subPath: `/ledger-items/${ledgerItemId}/log`,
        label: 'Ledger Item History',
        permission: PermissionsEnum.GLOBAL_LEDGER_CAN_ACCESS,
      }),
  },

  /**
   * Program calendar
   */
  programCalendar: {
    seasons: () => ({
      path: '/admin/program-calendar/seasons/',
      component: Pages.admin.programCalendar.SeasonManagerPage,
      label: 'Season Manager',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
    }),
    seasonEdit: (id: number | string = ':id') => ({
      parent: routes.admin.programCalendar.seasons(),
      path: `/admin/program-calendar/seasons/${id}/edit`,
      component: Pages.admin.programCalendar.SeasonEditPage,
      label: 'Edit Season',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
    }),
    seasonNew: () => ({
      parent: routes.admin.programCalendar.seasons(),
      path: '/admin/program-calendar/seasons/new',
      component: Pages.admin.programCalendar.SeasonNewPage,
      label: 'New Season',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
    }),
    subjects: () => ({
      path: '/admin/program-calendar/subjects/',
      component: Pages.admin.programCalendar.SubjectManagerPage,
      label: 'Subject Manager',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
    }),
    subjectEdit: (id: number | string = ':id') => ({
      parent: routes.admin.programCalendar.subjects(),
      path: `/admin/program-calendar/subjects/${id}/edit`,
      component: Pages.admin.programCalendar.SubjectEditPage,
      label: 'Edit Subject',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
    }),
    subjectNew: () => ({
      parent: routes.admin.programCalendar.subjects(),
      path: '/admin/program-calendar/subjects/new',
      component: Pages.admin.programCalendar.SubjectNewPage,
      label: 'New Subject',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
    }),
    classes: () => ({
      path: '/admin/program-calendar/classes/',
      component: Pages.admin.programCalendar.ClassManagerPage,
      label: 'Class Manager',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
      icon: faGraduationCap,
    }),
    classNew: () => ({
      parent: routes.admin.programCalendar.classes(),
      path: '/admin/program-calendar/classes/new',
      component: Pages.admin.programCalendar.ClassNewPage,
      label: 'New Class',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
    }),
    classEdit: (classId: number | string = ':classId') => ({
      parent: routes.admin.programCalendar.classes(),
      path: `/admin/program-calendar/classes/${classId}/edit`,
      component: Pages.admin.programCalendar.ClassEditPage,
      label: 'Edit Class',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
    }),
    classEdit_addClassSkillModal: (classId: number | string = ':classId') =>
      createModalRoute({
        parent: routes.admin.programCalendar.classEdit(classId),
        subPath: '/skills/new',
        label: 'Add Skill',
        permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
      }),
    classEdit_editClassSkillModal: (
      classId: number | string = ':classId',
      classSkillId: string = ':classSkillId'
    ) =>
      createModalRoute({
        parent: routes.admin.programCalendar.classEdit(classId),
        subPath: `/skills/${classSkillId}/edit`,
        label: 'Edit Skill',
        permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
      }),
    classInfoNew: (classId: number | string = ':classId') => ({
      parent: routes.admin.programCalendar.classEdit(classId),
      component: Pages.admin.programCalendar.ClassInfoNewPage,
      path: `/admin/program-calendar/classes/${classId}/details/new`,
      label: 'Add Class Details',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
    }),
    classInfoEdit: (
      classId: number | string = ':classId',
      classInfoId: string = ':classInfoId'
    ) => ({
      parent: routes.admin.programCalendar.classEdit(classId),
      component: Pages.admin.programCalendar.ClassInfoEditPage,
      path: `/admin/program-calendar/classes/${classId}/details/${classInfoId}/edit`,
      label: 'Edit Class Details',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
    }),
    classInfoEdit_addClassInfoSkillModal: (
      classId: number | string = ':classId',
      classInfoId: string = ':classInfoId'
    ) =>
      createModalRoute({
        parent: routes.admin.programCalendar.classInfoEdit(
          classId,
          classInfoId
        ),
        subPath: '/skills/new',
        label: 'Add Skill Schedule',
        permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
      }),
    classInfoEdit_editClassInfoSkillModal: (
      classId: number | string = ':classId',
      classInfoId: string = ':classInfoId',
      classInfoSkillId: string = ':classInfoSkillId'
    ) =>
      createModalRoute({
        parent: routes.admin.programCalendar.classInfoEdit(
          classId,
          classInfoId
        ),
        subPath: `/skills/${classInfoSkillId}/edit`,
        label: 'Edit Skill Schedule',
        permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
      }),
    classEdit_cloneClassInfoModal: (
      classId: number | string = ':classId',
      classInfoId: string = ':classInfoId'
    ) =>
      createModalRoute({
        parent: routes.admin.programCalendar.classEdit(classId),
        subPath: `/details/${classInfoId}/clone`,
        label: 'Clone Class Details',
        permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
      }),
    classRegistrations: (
      classId: number | string = ':classId',
      classInfoId: number | string = ':classInfoId'
    ) => ({
      parent: routes.admin.programCalendar.classEdit(classId),
      path: `/admin/program-calendar/class/${classId}/details/${classInfoId}/registrations`,
      component: Pages.admin.programCalendar.ClassRegistrationManagerPage,
      label: 'Class Registrations',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
    }),
    teacherPermissions: (
      classId: string = ':classId',
      classInfoId: number | string = ':classInfoId'
    ) => ({
      parent: routes.admin.programCalendar.classEdit(classId),
      path: `/admin/program-calendar/class/${classId}/details/${classInfoId}/teacher-permissions`,
      component: Pages.admin.programCalendar.TeacherPermissionManagerPage,
      label: 'Teacher Permissions Manager',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
    }),
    teacherPermissions_addTeacherPermissionModal: (
      classId: string = ':classId',
      classInfoId: number | string = ':classInfoId'
    ) =>
      createModalRoute({
        parent: routes.admin.programCalendar.teacherPermissions(
          classId,
          classInfoId
        ),
        subPath: `/new`,
        label: 'Add Teacher Permission',
        permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
      }),
    classAttendance: (
      classId: number | string = ':classId',
      classInfoId: number | string = ':classInfoId'
    ) => ({
      parent: routes.admin.programCalendar.classEdit(classId),
      path: `/admin/program-calendar/class/${classId}/details/${classInfoId}/attendance`,
      component: Pages.admin.programCalendar.ClassAttendancePage,
      label: 'Class Attendance',
      permission: PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
    }),
  },

  /**
   * Events
   */
  // TODO: update PermissionsEnum for granular event access
  event: {
    event: () => ({
      path: '/admin/event',
      component: Pages.staff.EventManagerPage,
      label: 'Event Manager',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventNew: () => ({
      path: '/admin/event/new',
      component: Pages.staff.EventManagerNewPage,
      parent: routes.admin.event.event(),
      label: 'New Event',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventEdit: (eventId: number | string = ':eventId') => ({
      path: `/admin/event/${eventId}/edit`,
      component: Pages.staff.EventManagerEditPage,
      parent: routes.admin.event.event(),
      label: 'Edit Event',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventRecurring: (eventId: number | string = ':eventId') => ({
      path: `/admin/event/${eventId}/event-recurring-schedules`,
      component: Pages.staff.EventRecurringManagerPage,
      parent: routes.admin.event.eventEdit(eventId),
      label: 'Event Recurring Schedule',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventRecurringEdit: (
      eventId: number | string = ':eventId',
      eventRecurringId: number | string = ':eventRecurringId'
    ) => ({
      path: `/admin/event/${eventId}/event-recurring-schedules/${eventRecurringId}/edit`,
      component: Pages.staff.EventManagerRecurringEditPage,
      parent: routes.admin.event.eventRecurring(eventId),
      label: 'Event Recurring Edit',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventRecurringNew: (eventId: number | string = ':eventId') => ({
      path: `/admin/event/${eventId}/event-recurring-schedules/new`,
      component: Pages.staff.EventManagerRecurringNewPage,
      parent: routes.admin.event.eventRecurring(eventId),
      label: 'Event Recurring New',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventRecurringSplit_addSplitModal: (
      eventId: number | string = ':eventId',
      eventRecurringId: number | string = ':eventRecurringId'
    ) =>
      createModalRoute({
        parent: routes.admin.event.eventRecurring(eventId),
        subPath: `/event/${eventId}/event-recurring-schedules/${eventRecurringId}/split`,
        label: 'Split Date',
        permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
      }),
    eventRegistrations: (
      eventId: number | string = ':eventId',
      schoolId?: number | string,
      startDateOnly?: string | null,
      endDateOnly?: string | null,
      startTimeOnly?: string | null,
      endTimeOnly?: string | null,
      eventDateOnly?: string | null
    ) => {
      let qs = '';
      let qsArray = [];
      if(schoolId){
        qsArray.push(`schoolId=${schoolId}`);
      }
      if (startDateOnly) {
        qsArray.push(`startDateOnly=${startDateOnly}`);
      }
      if (endDateOnly) {
        qsArray.push(`endDateOnly=${endDateOnly}`);
      }
      if (startTimeOnly) {
        qsArray.push(`startTimeOnly=${startTimeOnly}`);
      }
      if (endTimeOnly) {
        qsArray.push(`endTimeOnly=${endTimeOnly}`);
      }
      if (eventDateOnly) {
        qsArray.push(`eventDateOnly=${eventDateOnly}`);
      }
      if(qsArray.length > 0){
        qs = `?${qsArray.join('&')}`;
      }
      return {
        path: `/admin/event/${eventId}/registrations${qs}`,
        component: Pages.staff.EventManagerRegistrationsPage,
        parent: routes.admin.event.event(),
        label: 'Event Registrations',
        permission: PermissionsEnum.FAMILY_UPCOMING_EVENTS_CAN_ACCESS,
      };
    },
    eventRegistrationNew: (
      eventId: number | string = ':eventId',
      startDateOnly?: string | null,
      startTimeOnly?: string | null,
      endTimeOnly?: string | null,
      eventDateOnly?: string | null
    ) => {
      let qs = '';
      if (startDateOnly) {
        qs += `?startDateOnly=${startDateOnly}`;
      }
      if (startTimeOnly) {
        qs += `&startTimeOnly=${startTimeOnly}`;
      }
      if (endTimeOnly) {
        qs += `&endTimeOnly=${endTimeOnly}`;
      }
      if (eventDateOnly) {
        qs += `&eventDateOnly=${eventDateOnly}`;
      }
      return {
        path: `/admin/event/${eventId}/registrations/new${qs}`,
        component: Pages.staff.EventRegistrationNewPage,
        parent: routes.admin.event.eventRegistrations(eventId),
        label: 'New Event Registration',
        permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
      };
    },
    eventRegistrationEdit: (
      eventId: number | string = ':eventId',
      eventRegistrationId: number | string = ':eventRegistrationId'
    ) => ({
      path: `/admin/event/${eventId}/registrations/${eventRegistrationId}/edit`,
      component: Pages.staff.EventRegistrationEditPage,
      parent: routes.admin.event.eventRegistrations(eventId),
      label: 'Edit Event Registration',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventRegistrationComplete: (
      eventId: number | string = ':eventId',
      eventRegistrationId: number | string = ':eventRegistrationId',
      childIds?: string[]
    ) => {
      let qs = childIds ? `?childIds=${childIds}` : '';
      return {
        path: `/admin/event/${eventId}/registrations/${eventRegistrationId}/complete${qs}`,
        component: Pages.staff.EventRegistrationCompletePage,
        parent: routes.admin.event.eventRegistrationEdit(
          eventId,
          eventRegistrationId
        ),
        label: 'Complete Event Registration',
        permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
      };
    },
    // really need this to fix the navigation issues with misc
    eventRegistrationMiscComplete: (
      eventId: number | string = ':eventId',
      familyId: number | string = ':familyId',
      eventRegistrationId: number | string = ':eventRegistrationId',
      childIds?: string[]
    ) => {
      let qs = childIds ? `?childIds=${childIds}` : '';
      return {
        path: `/admin/event/${eventId}/registrations/${eventRegistrationId}/family/${familyId}/complete${qs}`,
        component: Pages.staff.EventRegistrationCompletePage,
        parent: routes.admin.event.eventRegistrationMiscellaneousEdit(
          eventId,
          eventRegistrationId,
          familyId
        ),
        label: 'Complete Event Registration',
        permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
      };
    },
    eventRegistrationCompleted: () => ({
      path: `/admin/event/registrations/completed`,
      component: Pages.staff.EventRegistrationCompletedPage,
      label: 'Registration Completed',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    eventRegistrationMiscellaneousNew: (
      eventId: number | string = ':eventId',
      familyId: number | string = ':familyId',
      eventDateOnly?: string | null,
      startTimeOnly?: string | null,
      endTimeOnly?: string | null
    ) => {
      let qs = eventDateOnly ? `?eventDateOnly=${eventDateOnly}` : '';
      if (startTimeOnly) qs += `&startTimeOnly=${startTimeOnly}`;
      if (endTimeOnly) qs += `&endTimeOnly=${endTimeOnly}`;
      return {
        path: `/admin/event/${eventId}/registrations/family/${familyId}/new${qs}`,
        component: Pages.staff.EventRegistrationMiscellaneousNewPage,
        parent: routes.admin.event.eventRegistrations(eventId),
        label: 'New Event Miscellaneous Registration',
      };
    },
    eventRegistrationMiscellaneousEdit: (
      eventId: number | string = ':eventId',
      eventRegistrationId: number | string = ':eventRegistrationId',
      familyId: number | string = ':familyId',
      selectedChildren?: string[]
    ) => {
      let qs = selectedChildren ? `?selectedChildren=${encodeURIComponent(selectedChildren.join(','))}` : '';
      return {
      path: `/admin/event/${eventId}/registrations/${eventRegistrationId}/misc/family/${familyId}${qs}`,
      component: Pages.staff.EventRegistrationMiscellaneousEditPage,
      parent: routes.admin.event.eventRegistrations(eventId),
      label: 'Update Event Miscellaneous Registration',
      }
    },
    addon: () => ({
      path: '/admin/event/addon',
      component: Pages.staff.EventAddonManagerPage,
      label: 'Event Addon Manager',
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    addonNew: () => ({
      path: '/admin/event/addon/new',
      component: Pages.staff.EventAddonManagerNewPage,
      label: 'New Event Addon',
      parent: routes.admin.event.addon(),
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    addonEdit: (eventAddOnId: number | string = ':eventAddOnId') => ({
      path: `/admin/event/addon/${eventAddOnId}/edit`,
      component: Pages.staff.EventAddonManagerEditPage,
      label: 'Edit Event Addon',
      parent: routes.admin.event.addon(),
      permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
    }),
    addonEdit_schoolEditModal: (
      eventAddOnId: number | string = ':eventAddOnId',
      eventAddOnSchoolId: number | string = ':eventAddOnSchoolId'
    ) =>
      createModalRoute({
        parent: routes.admin.event.addonEdit(eventAddOnId),
        subPath: `/schools/${eventAddOnSchoolId}/edit`,
        label: 'Edit Establishing Amount For School',
        permission: PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS,
      }),
  },

  /**
   * Reports
   */
  reports: {
    byCategory: (
      reportCategoryId: string | number = ':reportCategoryId',
      label = 'Reports'
    ) => ({
      path: `/admin/reports/by-category/${reportCategoryId}`,
      component: Pages.admin.reports.ReportsByCategoryPage,
      label,
      permission: PermissionsEnum.ADMIN_REPORT_CAN_ACCESS,
      icon: faClipboardList,
    }),
    report: (reportId: string | number = ':reportId', label = 'Reports') => ({
      path: `/admin/reports/${reportId}`,
      component: Pages.admin.reports.Report,
      label,
      permission: PermissionsEnum.ADMIN_REPORT_CAN_ACCESS,
      icon: faClipboardList,
    }),
  },

  /**
   * Site users
   */
  user: {
    user: () => ({
      path: '/admin/user/user',
      component: Pages.admin.services.UserManagerPage,
      label: 'User Manager',
      permission: PermissionsEnum.ADMIN_USER_MANAGER_CAN_ACCESS,
    }),
    userNew: () => ({
      path: '/admin/user/user/new',
      component: Pages.admin.services.UserNewPage,
      label: 'New User',
      permission: PermissionsEnum.ADMIN_USER_MANAGER_CAN_ACCESS,
    }),
    userEdit: (id: number | string = ':id') => ({
      parent: routes.admin.user.user(),
      path: `/admin/user/user/${id}/edit`,
      component: Pages.admin.services.UserEditPage,
      label: 'Edit User',
      permission: PermissionsEnum.ADMIN_USER_MANAGER_CAN_ACCESS,
    }),
    securityQuestion: () => ({
      path: '/admin/user/security-question',
      component: Pages.ComingSoonPage,
      label: 'Security Question Manager',
      permission: PermissionsEnum.ADMIN_USER_MANAGER_CAN_ACCESS,
    }),
  },

  /**
   * Payment gateway
   */
  paymentGateway: {
    achProcessReturns: () => ({
      path: '/admin/payment-gateway/ach-process-returns',
      component: Pages.admin.services.ACHProcessReturnsPage,
      label: 'ACH Process Returns',
      permission: PermissionsEnum.ADMIN_PAYMENT_GATEWAY_FULL_ACCESS,
    }),
  },

  /**
   * Front desk
   */
  frontDesk: {
    generalAdmission: () => ({
      path: '/admin/front-desk/general-admission',
      component: Pages.admin.frontDesk.MembershipGAPage,
      label: 'Membership (G/A) Manager',
      permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
    }),
    generalAdmisionEdit: (
      id: number | string = ':admissionPersonId',
      dateOnly?: string,
      schoolId?: number | string
    ) => {
      let qs = '';
      if (dateOnly && schoolId) {
        qs += `?dateOnly=${dateOnly}&schoolId=${schoolId}`;
      }
      return {
        parent: routes.admin.frontDesk.generalAdmission(),
        path: `/admin/front-desk/general-admission/${id}/edit${qs}`,
        component: Pages.admin.frontDesk.MembershipGAEditPage,
        label: 'Edit Membership (G/A)',
        permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
      };
    },
    generalAdmisionNew: (
      dateOnly?: string,
      schoolId?: number | string,
      childId?: number | string
    ) => {
      let qs = '';
      if (dateOnly && schoolId && childId) {
        qs += `?dateOnly=${dateOnly}&schoolId=${schoolId}&childId=${childId}`;
      }
      return {
        parent: routes.admin.frontDesk.generalAdmission(),
        path: `/admin/front-desk/general-admission/new${qs}`,
        component: Pages.admin.frontDesk.MembershipGANewPage,
        label: 'New Membership (G/A)',
        permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
      };
    },
    programAdmission: () => ({
      path: '/admin/front-desk/program-admission',
      component: Pages.admin.frontDesk.ProgramAdmissionManagerPage,
      label: 'Program (Check-in) Manager',
      permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
    }),
    programAdmisionNew: (
      childId: string | string = ':childId',
      dateOnly?: string,
      programTypeId?: number,
      schoolId?: number
    ) => {
      const qs = buildQueryString([
        { dateOnly },
        { programTypeId },
        { schoolId },
      ]);
      return {
        parent: routes.admin.frontDesk.programAdmission(),
        path: `/admin/front-desk/program-admission/${childId}/new/${qs}`,
        component: Pages.admin.frontDesk.ProgramAdmissionNewPage,
        label: 'Add Program Admission',
        permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
      };
    },
    programAdmisionEdit: (
      admissionPersonId: string | string = ':admissionPersonId',
      dateOnly?: string,
      schoolId?: number | string
    ) => {
      const qs = buildQueryString([{ dateOnly }, { schoolId }]);
      return {
        parent: routes.admin.frontDesk.programAdmission(),
        path: `/admin/front-desk/program-admission/${admissionPersonId}/${qs}`,
        component: Pages.admin.frontDesk.ProgramAdmissionEditPage,
        label: 'Edit Program Admission',
        permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
      };
    },
  },

  /**
   * Agency Assistance
   */
  agencyAssistance: {
    agencies: () => ({
      path: '/admin/agency-assistance/agencies/',
      component: Pages.admin.agencyAssistance.AgencyManagerPage,
      label: 'Agency Manager',
      permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
    }),
    agencyEdit: (id: number | string = ':id') => ({
      parent: routes.admin.agencyAssistance.agencies(),
      path: `/admin/agency-assistance/agencies/${id}/edit`,
      component: Pages.admin.agencyAssistance.AgencyEditPage,
      label: 'Edit Agency',
      permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
    }),
    agencyEdit_agencySchoolNewModal: (id: number | string = ':id') =>
      createModalRoute({
        parent: routes.admin.agencyAssistance.agencyEdit(id),
        subPath: '/schools/new',
        label: 'New Agency School',
        permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
      }),
    agencyEdit_agencySchoolEditModal: (
      id: number | string = ':id',
      agencySchoolId: number | string = ':agencySchoolId'
    ) =>
      createModalRoute({
        parent: routes.admin.agencyAssistance.agencyEdit(id),
        subPath: `/schools/${agencySchoolId}/edit`,
        label: 'Edit Agency School',
        permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
      }),
    agencyNew: () => ({
      parent: routes.admin.agencyAssistance.agencies(),
      path: '/admin/agency-assistance/agencies/new',
      component: Pages.admin.agencyAssistance.AgencyNewPage,
      label: 'New Agency',
      permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
    }),
    agencyLogs: () => ({
      path: '/admin/agency-assistance/agency-logs',
      component: Pages.admin.agencyAssistance.AgencyLogManagerPage,
      label: 'Agency Logs',
      permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_LOGS_CAN_ACCESS,
    }),
    agencyLogs_newModal: () =>
      createModalRoute({
        parent: routes.admin.agencyAssistance.agencyLogs(),
        subPath: '/new',
        label: 'New Agency Log',
        permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
      }),
    agencyLogs_editModal: (agencyLogId: number | string = ':agencyLogId') =>
      createModalRoute({
        parent: routes.admin.agencyAssistance.agencyLogs(),
        subPath: `/${agencyLogId}/edit`,
        label: 'Edit Agency Log',
        permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
      }),
    agencyLogs_historyModal: (agencyLogId: number | string = ':agencyLogId') =>
      createModalRoute({
        parent: routes.admin.agencyAssistance.agencyLogs(),
        subPath: `/${agencyLogId}/history`,
        label: 'Agency Log History',
        permission: PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS,
      }),
  },

  /**
   * Settings
   */
  settings: {
    settings: () => ({
      path: '/admin/settings',
      component: Pages.admin.services.SettingManagerPage,
      label: 'Settings Manager',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
    settingEdit: (id: number | string = ':id') => ({
      parent: routes.admin.settings.settings(),
      path: `/admin/settings/${id}/edit`,
      component: Pages.admin.services.SettingEditPage,
      label: 'Edit Setting',
      permission: PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS,
    }),
  },
};

/**
 * Dev routes should have paths prefixed with /dev,
 * and should load pages from the /pages/dev directory.
 */
export const devRoutes = {
  home: () => ({
    path: '/dev',
    component: Pages.dev.HomePage,
    label: 'Overview',
  }),
  components: () => ({
    path: '/dev/components',
    component: Pages.dev.ComponentExamplesPage,
    label: 'Components',
  }),
  theme: () => ({
    path: '/dev/theme',
    component: Pages.dev.ThemeExamplesPage,
    label: 'Theme',
  }),
  minimalDataGrid: () => ({
    path: '/dev/minimal-data-grid',
    component: Pages.dev.MinimalDataGridExamplePage,
    label: 'Minimal Data-grid',
  }),
  minimalForm: () => ({
    path: '/dev/minimal-form',
    component: Pages.dev.MinimalFormExamplePage,
    label: 'Minimal Form',
  }),
  formInputs: () => ({
    path: '/dev/form-inputs',
    component: Pages.dev.FormInputExamplesPage,
    label: 'Form Inputs',
  }),
  wumpusManager: () => ({
    path: '/dev/wumpus',
    component: Pages.dev.WumpusManagerPage,
    label: 'Wumpus Manager',
  }),
  wumpusEdit: (id: number | string = ':id') => ({
    path: `/dev/wumpus/${id}`,
    component: Pages.dev.WumpusEditPage,
    label: 'Edit Wumpus',
  }),
  wumpusNew: () => ({
    path: '/dev/wumpus/new',
    component: Pages.dev.WumpusNewPage,
    label: 'New Wumpus',
  }),
  pdfExample: () => ({
    path: '/dev/pdfexample',
    component: Pages.dev.PDFExamplePage,
    label: 'PDF Example',
  }),
};

/**
 * Front-desk routes should have paths prefixed with /front-desk,
 * and should load pages from the /pages/front-desk directory.
 */
export const frontDeskRoutes = {
  quickCheckIn: () => ({
    path: '/front-desk',
    component: Pages.frontDesk.QuickCheckIn,
    label: 'Quick Check In',
    icon: faTabletAlt,
  }),
  programCheckIn: () => ({
    path: '/front-desk/program-check-in',
    component: Pages.frontDesk.ProgramCheckIn,
    label: 'Program Check In',
    icon: faGraduationCap,
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
  bus: () => ({
    path: '/front-desk/bus',
    component: Pages.frontDesk.Bus,
    label: 'Bus Check In & Check Out',
    icon: faBus,
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
  busCheckInAndCheckOut: (
    busMode: BusMode | ':busMode' = ':busMode',
    pubSchoolId: string = ':pubSchoolId'
  ) => ({
    path: `/front-desk/bus/${busMode}/${pubSchoolId}`,
    parent: routes.frontDesk.bus(),
    component: Pages.frontDesk.Bus,
    label:
      busMode === 'check-in'
        ? 'Bus Check In'
        : busMode === 'check-out'
        ? 'Bus Check Out'
        : 'Bus Check In & Check Out',
    icon: faBus,
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
  /**
   * Several member & guest routes all use the same page components,
   * but use a "groupId" parameter to determine the behavior of the page.
   */
  memberGuestGroupCheckIn: () => ({
    path: `/front-desk/member-guest-group/new`,
    component: Pages.frontDesk.MemberGuestGroup,
    label: 'Member & Guest Check In',
    icon: faPersonWalkingArrowRight,
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
  memberGuestGroupManage: (groupId: number | string = ':groupId') => ({
    parent: routes.frontDesk.whosHere(),
    path: `/front-desk/member-guest-group/${groupId}/edit`,
    component: Pages.frontDesk.MemberGuestGroup,
    label: 'Member & Guest Group',
  }),
  memberGuestGroupManageCompleted: () => ({
    parent: routes.frontDesk.whosHere(),
    path: `/front-desk/member-guest-group/completed`,
    component: Pages.frontDesk.MemberGuestGroupCompleted,
    label: 'Complete',
  }),

  whosHere: () => ({
    path: '/front-desk/whos-here',
    component: Pages.frontDesk.WhosHere,
    label: 'Who’s Here',
    icon: faClipboardList,
  }),
  whosHereByAdmissionTypePrintout: (
    admissionTypeId: number | string = ':admissionTypeId'
  ) => ({
    parent: routes.frontDesk.whosHere(),
    path: `/front-desk/whos-here/admission-type/${admissionTypeId}/printout`,
    component: Pages.frontDesk.WhosHerePrintout,
    label: 'Print Here',
  }),
  whosHereByProgramTypePrintout: (
    programTypeId: number | string = ':programTypeId',
    mode: ':mode' | 'here' | 'not-here' = ':mode'
  ) => ({
    parent: routes.frontDesk.whosHere(),
    path: `/front-desk/whos-here/program-type/${programTypeId}/printout/${mode}/`,
    component: Pages.frontDesk.WhosHerePrintout,
    label: mode === 'not-here' ? 'Print Not Here' : 'Print Here',
  }),
  whosHereByProgramType: (
    programTypeId: number | string = ':programTypeId'
  ) => ({
    parent: routes.frontDesk.whosHere(),
    path: `/front-desk/whos-here/program-type/${programTypeId}`,
    component: Pages.frontDesk.WhosHereByProgramType,
    label: 'Who’s Here',
  }),
  whosHereByProgramGroup: (
    programTypeId: number | string = ':programTypeId',
    programId: number | string = ':programId',
    programGroupId: number | string = ':programGroupId'
  ) => ({
    parent: routes.frontDesk.whosHereByProgramType(programTypeId),
    path: `/front-desk/whos-here/program-type/${programTypeId}/program/${programId}/group/${programGroupId}`,
    component: Pages.frontDesk.WhosHereByProgramGroup,
    label: 'Who’s Here',
  }),
  checkOut: () => ({
    path: '/front-desk/check-out',
    component: Pages.frontDesk.CheckOut,
    label: 'Program Check Out',
    icon: faDoorOpen,
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
  checkOutChild: (child?: WhosHereChildSearchable) => ({
    ...routes.frontDesk.checkOut(),
    path: `/front-desk/check-out?child=${toBase64Url(
      JSON.stringify(child, null, '')
    )}`,
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
  takePayment: () => ({
    path: '/front-desk/take-payment',
    component: Pages.frontDesk.TakePayment,
    label: 'Take Payment',
    icon: faCircleDollarToSlot,
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
  takePaymentCompletedCompleted: () => ({
    path: '/front-desk/take-payment/completed',
    component: Pages.frontDesk.TakePaymentCompleted,
    label: 'Purchase Complete',
    permission: PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS,
  }),
};

/**
 * Parent routes should have paths prefixed with /parent,
 * and should load pages from the /pages/parent directory.
 */
export const parentRoutes = {
  home: () => ({
    path: `/dashboard`,
    component: Pages.parentPortal.FamilyDashboardPage,
    label: 'Dashboard',
  }),
  info: () => ({
    path: '/info',
    component: Pages.parentPortal.ParentPortalInfoOptions,
    label: 'Info',
  }),
  caregivers: () => ({
    path: '/caregivers',
    component: Pages.parentPortal.ParentPortalCaregivers,
    label: 'Caregivers',
  }),
  caregiverNew: () => ({
    parent: parentRoutes.caregivers(),
    path: `/caregivers/new`,
    component: Pages.parentPortal.ParentPortalCaregiverNewPage,
    label: 'New Caregiver',
  }),
  caregiverEdit: (caregiverId: string = ':caregiverId') => ({
    parent: parentRoutes.caregivers(),
    path: `/caregivers/${caregiverId}/edit`,
    component: Pages.parentPortal.ParentPortalCaregiverEditPage,
    label: 'Edit Caregiver',
  }),
  children: () => ({
    path: `/children`,
    component: Pages.parentPortal.ParentPortalChildren,
    label: 'Children',
  }),
  childDetails: (childId: string = ':childId') => ({
    parent: parentRoutes.children(),
    path: `/children/${childId}/edit`,
    component: Pages.parentPortal.ParentPortalChildrenDetailsPage,
    label: 'Child Details',
  }),
  enroll: (childId?: string, params?: SchoolScheduleSearchParams) => {
    /* Building the qs */
    const { subjectId, schoolId, startTimeOnly, school, seasonTypeId, days } =
      params || {};

    const qs = buildQueryString([
      { childId },
      { school },
      { schoolId },
      { subjectId },
      { startTimeOnly },
      { seasonTypeId },
      { days },
    ]);
    return {
      path: `/enroll/${qs}`,
      component: Pages.parentPortal.ParentPortalEnroll,
      label: 'Enroll in a Class',
    };
  },

  classDetailEnrollment: (
    childId: string = ':childId',
    classInfoId: string = ':classInfoId',
    params?: SchoolScheduleSearchParams
  ) => {
    /* Building the qs */
    const { subjectId, schoolId, startTimeOnly, school, seasonTypeId, days } =
      params || {};

    const qs = buildQueryString([
      { schoolId },
      { school },
      { subjectId },
      { startTimeOnly },
      { seasonTypeId },
      { days },
    ]);
    return {
      parent: routes.parent.enroll(childId, params),
      component: Pages.parentPortal.ParentPortalClassInfoPage,
      path: `/enroll/class-detail/${classInfoId}/child/${childId}/${qs}`,
      label: 'Class Details',
    };
  },
  classEnrollmentComplete: (
    familyId: string = ':familyId',
    childId: string = ':childId',
    classInfoId: string = ':classInfoId',
    seasonId: string | number = ':seasonId',
    params?: SchoolScheduleSearchParams
  ) => {
    /* Building the qs */
    const { subjectId, schoolId, startTimeOnly, school, seasonTypeId, days } =
      params || {};

    const qs = buildQueryString([
      { schoolId },
      { school },
      { subjectId },
      { startTimeOnly },
      { seasonTypeId },
      { days },
    ]);
    return {
      parent: routes.parent.enroll(childId, params),
      path: `/enroll/${familyId}/child/${childId}/class/${classInfoId}/season/${seasonId}/complete/${qs}`,
      component: Pages.parentPortal.ParentPortalClassEnrollmentComplete,
      label: 'Complete Enrollment',
    };
  },
  classEnrollmentCompleted: (familyId: string = ':familyId') => ({
    parent: routes.parent.enroll(),
    path: `/enroll/${familyId}/classes-enrollment/completed`,
    component: Pages.parentPortal.ParentPortalClassEnrollmentCompleted,
    label: 'Class Enrollment Complete',
  }),
  programSchedule: () => ({
    path: '/program-schedule',
    component: Pages.parentPortal.ParentPortalProgramSchedule,
    label: 'Current Programs',
  }),
  programSchedule_previousProgramsModal: (childId: string = ':childId') =>
    createModalRoute({
      parent: routes.parent.programSchedule(),
      subPath: `/${childId}/previous-enrollments`,
      label: 'Previous Programs',
      // permission: PermissionsEnum.FAMILY_PREVIOUS_PROGRAMS_CAN_ACCESS,
    }),
  programSchedule_historyModal: (
    programEnrollmentId: string = ':programEnrollmentId'
  ) =>
    createModalRoute({
      parent: routes.parent.programSchedule(),
      subPath: `/${programEnrollmentId}/history`,
      label: 'Program Enrollment History',
    }),
  programSchedule_historyDetailsModal: (
    programEnrollmentId: string = ':programEnrollmentId',
    _seq: string | number = ':_seq'
  ) =>
    createModalRoute({
      parent: routes.parent.programSchedule_historyModal(
        programEnrollmentId
      ),
      subPath: `/${programEnrollmentId}/history/${_seq}/details`,
      label: 'Program Enrollment History Detail',
    }),
  classSchedule: () => ({
    path: '/class-schedule',
    component: Pages.parentPortal.ParentPortalClassSchedule,
    label: 'Class Schedule',
  }),
  classDetailSchedule: (
    childId: string = ':childId',
    classInfoId: string = ':classInfoId'
  ) => ({
    parent: routes.parent.classSchedule(),
    component: Pages.parentPortal.ParentPortalClassInfoPage,
    path: `/class-schedule/class-detail/${classInfoId}/child/${childId}`,
    label: 'Class Details',
  }),

  classSchedule_previousClassesModal: (childId: string = ':childId') =>
    createModalRoute({
      parent: routes.parent.classSchedule(),
      subPath: `/${childId}/previous-enrollments`,
      label: 'Previous Classes',
    }),
  classSchedule_classDetailsModal: (classId: string = ':classId') =>
    createModalRoute({
      parent: routes.parent.classSchedule(),
      subPath: `/class/${classId}/details`,
      label: 'Class Details',
    }),
  events: () => ({
    path: `/events`,
    component: Pages.parentPortal.ParentPortalUpcomingEventsPage,
    label: 'Events',
  }),
  eventRegistrationMiscellaneousNew: (
    eventId: number | string = ':eventId',
    eventDateOnly?: string,
    startTimeOnly?: string,
    endTimeOnly?: string
  ) => {
    let qs = eventDateOnly ? `?eventDateOnly=${eventDateOnly}` : '';
    if (startTimeOnly) qs += `&startTimeOnly=${startTimeOnly}`;
    if (endTimeOnly) qs += `&endTimeOnly=${endTimeOnly}`;
    return {
      path: `/event/${eventId}/registrations/new${qs}`,
      component: Pages.parentPortal.ParentPortalEventRegistrationNewPage,
      parent: routes.parent.events(),
      label: 'New Event Registration',
    };
  },
  eventRegistrationMiscellaneousEdit: (
    eventId: number | string = ':eventId',
    eventRegistrationId: number | string = ':eventRegistrationId'
  ) => ({
    path: `/event/${eventId}/registrations/${eventRegistrationId}`,
    component: Pages.parentPortal.ParentPortalEventRegistrationEditPage,
    parent: routes.parent.events(),
    label: 'Update Event Registration',
  }),
  eventRegistrationComplete: (
    eventId: number | string = ':eventId',
    eventRegistrationId: number | string = ':eventRegistrationId',
    childIds?: string[]
  ) => {
    let qs = childIds ? `?childIds=${childIds}` : '';
    return {
      path: `/event/${eventId}/registrations/${eventRegistrationId}/complete${qs}`,
      component: Pages.parentPortal.ParentPortalEventRegistrationCompletePage,
      parent: routes.parent.eventRegistrationMiscellaneousEdit(
        eventId,
        eventRegistrationId
      ),
      label: 'Complete Event Registration',
    };
  },
  eventRegistrationCompleted: () => ({
    path: `/event/registrations/completed`,
    component: Pages.parentPortal.ParentPortalEventRegistrationCompletedPage,
    label: 'Registration Completed',
  }),
  ledger: () => ({
    path: '/ledger',
    component: Pages.parentPortal.LedgerOverviewPage,
    label: 'Ledger Overview',
  }),
  /* This is WIP. It probably will change after working on the corresponding ticket  */
  ledgerMakePayment: () => ({
    path: `/legder/make-payment`,
    component: Pages.parentPortal.FamilyMakePaymentPage,
    label: 'Make Payment',
  }),
  ledgerMakePaymentCompleted: () => ({
    path: `/legder/make-payment/completed`,
    component: Pages.staff.FamilyMakePaymentCompletedPage,
    label: 'Payment Complete',
  }),
  ledgerOverviewEstimatedBilling: () =>
    createModalRoute({
      parent: parentRoutes.ledger(),
      subPath: `/estimated-payments`,
      label: 'Estimated Billing',
    }),
  ledgerDetails: () => ({
    path: `/ledger-details`,
    component: Pages.parentPortal.LedgerDetailsPage,
    label: 'Ledger Items',
  }),
  ledgerDetailsEstimatedBilling: () =>
    createModalRoute({
      parent: parentRoutes.ledgerDetails(),
      subPath: `/estimated-payments`,
      label: 'Estimated Billing',
    }),
  ledgerReport: () => ({
    path: `/ledger-report`,
    component: Pages.parentPortal.LedgerReportPage,
    label: 'Ledger Report',
  }),
};

/**
 * Teacher-tablet routes should have paths prefixed with /teacher-tablet,
 * and should load pages from the /pages/teacher-tablet directory.
 */
export const teacherTabletRoutes = {
  home: () => ({
    path: '/teacher-tablet',
    component: Pages.teacherTablet.HomePage,
    label: 'Dashboard',
    icon: faGrip,
  }),
  rollCall: () => ({
    path: '/teacher-tablet/roll-call',
    component: Pages.ComingSoonPage,
    label: 'Roll Call',
    icon: faHand,
  }),
  checkIn: () => ({
    path: '/teacher-tablet/check-in',
    component: Pages.ComingSoonPage,
    label: 'Check In',
    icon: faPersonWalkingArrowRight,
  }),
  checkOut: () => ({
    path: '/teacher-tablet/check-out',
    component: Pages.ComingSoonPage,
    label: 'Check Out',
    icon: faDoorOpen,
  }),
};

/**
 * Kiosk routes should have paths prefixed with /kiosk,
 * and should load pages from the /pages/kiosk directory.
 */
export const kioskRoutes = {
  family: () => ({
    path: '/kiosk',
    component: Pages.kiosk.KioskFamilyPage,
    label: 'Check In',
  }),
  setup: () => ({
    path: '/kiosk/setup',
    component: Pages.kiosk.KioskSetupPage,
    label: 'Setup',
  }),
};

export const routes = {
  ...rootRoutes,
  admin: adminRoutes,
  dev: devRoutes,
  frontDesk: frontDeskRoutes,
  parent: parentRoutes,
  root: rootRoutes,
  teacherTablet: teacherTabletRoutes,
  kiosk: kioskRoutes,
};
